<template>
  <div class="movement-summary" v-if="movement" ref="printableContent">
    <b-button variant="primary" class="my-1" @click="printMovement">
      Imprimir Romaneio
    </b-button>

    <b-card header="Informações básicas">
      <p>
        <strong>ID do Romaneio:</strong>
        {{ movement.id }}
      </p>
      <p>
        <strong>Criado em:</strong>
        {{ formatDate(movement.created_at) }}
      </p>
      <p>
        <strong>Baixa em:</strong>
        {{ formatDate(movement.date_completed) }}
      </p>
      <p>
        <strong>Criado Por:</strong>
        {{ movement.user.full_name }}
      </p>
      <p>
        <strong>Total:</strong>
        {{ movement.total_value | toCurrency }}
      </p>
    </b-card>
    <b-card no-body class="mb-1">
      <b-table
        small
        :items="movement.items"
        responsive
        :fields="itemTableColumns"
        show-empty
        empty-text="Nenhum item associado"
      >
        <template #cell(purchaseOrderInstallment.date)="{ item }">
          {{ formatDate(item.purchaseOrderInstallment.date) }}
        </template>
        <template #cell(purchaseOrderInstallment.total)="{ item }">
          {{ item.purchaseOrderInstallment.total | toCurrency }}
        </template>
        <template #cell(purchaseOrderInstallment.total_final)="{ item }">
          {{ item.purchaseOrderInstallment.total_final | toCurrency }}
        </template>
        <template #cell(purchaseOrderInstallment.order.total)="{ item }">
          {{ item.purchaseOrderInstallment.order.total | toCurrency }}
        </template>
        <template #cell(purchaseOrderInstallment.order.installments)="{ item }">
          {{ item.purchaseOrderInstallment.order.installments }}
        </template>
        <template
          #cell(purchaseOrderInstallment.order.orderDocumentType.name)="{
            item,
          }"
        >
          {{ item.purchaseOrderInstallment.order.orderDocumentType.name }}
        </template>
        <template #cell(purchaseOrderInstallment.status.name)="{ item }">
          <span
            :style="`background-color: ${item.purchaseOrderInstallment.status.background}; color: ${item.purchaseOrderInstallment.status.color};padding: 2px 5px; border-radius: 5px; display: block; align-items: center; align-content: center;`"
          >
            {{ item.purchaseOrderInstallment.status.name }}
          </span>
        </template>
        <template #cell(purchaseOrder)="{ item }">
          {{ formatDate(item.purchaseOrder.created_at) }}
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardGroup,
  BButton,
} from "bootstrap-vue";

import moment from "moment";

export default {
  components: {
    BCard,
    BTable,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BCardGroup,
    BButton,
  },
  data() {
    return {
      movement: null,
      itemTableColumns: [
        {
          key: "purchaseOrderInstallment.order.id",
          label: "PC ID",
          class: "text-center",
        },
        {
          key: "purchaseOrderInstallment.order.supplier.company_name",
          label: "Fornecedor",
          class: "text-center",
        },
        {
          key: "purchaseOrderInstallment.status.name",
          label: "Status",
          class: "text-center",
        },
        {
          key: "purchaseOrderInstallment.installment",
          label: "Parcela",
          class: "text-center",
        },
        {
          key: "purchaseOrderInstallment.total_final",
          label: "Total da Parcela",
          class: "text-center",
        },
        {
          key: "purchaseOrderInstallment.order.total",
          label: "Total do Pedido",
          class: "text-center",
        },
        {
          key: "purchaseOrderInstallment.order.orderDocumentType.name",
          label: "Tipo do Pedido",
          class: "text-center",
        },
        {
          key: "purchaseOrderInstallment.order.document_number",
          label: "Num Doc",
          class: "text-center",
        },
        {
          key: "purchaseOrderInstallment.order.installments",
          label: "Parcelas do Pedido",
          class: "text-center",
        },
        {
          key: "purchaseOrderInstallment.bank.name",
          label: "Banco",
          class: "text-center",
        },
        {
          key: "purchaseOrderInstallment.bank.account_number",
          label: "Conta",
          class: "text-center",
        },
        {
          key: "purchaseOrderInstallment.bank.agency",
          label: "Agencia",
          class: "text-center",
        },
        {
          key: "purchaseOrderInstallment.date",
          label: "Data",
          class: "text-center",
        },
      ],
    };
  },
  async mounted() {
    const uuid = this.$route.params.uuid;

    try {
      const response = await this.$store.dispatch(
        "OrderPurchase/getPaymentMovementByUuid",
        uuid
      );
      this.movement = response;
    } catch (error) {
      console.error("Erro ao carregar movimento:", error);
    }
  },
  methods: {
    printMovement() {
      const content = this.$refs.printableContent.innerHTML;

      const printWindow = window.open("", "_blank");

      printWindow.document.write(`
        <html>
          <head>
            <title>Romaneio #${this.movement.id}</title>
            <style>
              body {
                font-family: Arial, sans-serif;
              }
              .table {
                width: 100%;
                border-collapse: collapse;
              }
              .table th, .table td {
                border: 1px solid #ddd;
                padding: 8px;
              }
              .table th {
                background-color: #f2f2f2;
                text-align: left;
              }
            </style>
          </head>
          <body onload="window.print(); window.close();">
            <div class="container">
              ${content}
            </div>
          </body>
        </html>
      `);

      printWindow.document.close();
    },
    formatDate(value) {
      if (!value) return "";
      return moment(value).format("DD/MM/YYYY");
    },
    setOrderStep(item) {
      const oldStep = item.oldStepRelation ? item.oldStepRelation.name : "";
      const newStep = item.newStepRelation
        ? item.newStepRelation.name
        : "Pagamento";
      return `${oldStep} >> ${newStep}`;
    },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 5px;
}

.in-words {
  font-style: italic;
  color: #666;
}
</style>
