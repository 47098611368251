var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.movement
    ? _c(
        "div",
        { ref: "printableContent", staticClass: "movement-summary" },
        [
          _c(
            "b-button",
            {
              staticClass: "my-1",
              attrs: { variant: "primary" },
              on: { click: _vm.printMovement },
            },
            [_vm._v(" Imprimir Romaneio ")]
          ),
          _c("b-card", { attrs: { header: "Informações básicas" } }, [
            _c("p", [
              _c("strong", [_vm._v("ID do Romaneio:")]),
              _vm._v(" " + _vm._s(_vm.movement.id) + " "),
            ]),
            _c("p", [
              _c("strong", [_vm._v("Criado em:")]),
              _vm._v(
                " " + _vm._s(_vm.formatDate(_vm.movement.created_at)) + " "
              ),
            ]),
            _c("p", [
              _c("strong", [_vm._v("Baixa em:")]),
              _vm._v(
                " " + _vm._s(_vm.formatDate(_vm.movement.date_completed)) + " "
              ),
            ]),
            _c("p", [
              _c("strong", [_vm._v("Criado Por:")]),
              _vm._v(" " + _vm._s(_vm.movement.user.full_name) + " "),
            ]),
            _c("p", [
              _c("strong", [_vm._v("Total:")]),
              _vm._v(
                " " +
                  _vm._s(_vm._f("toCurrency")(_vm.movement.total_value)) +
                  " "
              ),
            ]),
          ]),
          _c(
            "b-card",
            { staticClass: "mb-1", attrs: { "no-body": "" } },
            [
              _c("b-table", {
                attrs: {
                  small: "",
                  items: _vm.movement.items,
                  responsive: "",
                  fields: _vm.itemTableColumns,
                  "show-empty": "",
                  "empty-text": "Nenhum item associado",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(purchaseOrderInstallment.date)",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatDate(
                                  item.purchaseOrderInstallment.date
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(purchaseOrderInstallment.total)",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("toCurrency")(
                                  item.purchaseOrderInstallment.total
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(purchaseOrderInstallment.total_final)",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("toCurrency")(
                                  item.purchaseOrderInstallment.total_final
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(purchaseOrderInstallment.order.total)",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("toCurrency")(
                                  item.purchaseOrderInstallment.order.total
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(purchaseOrderInstallment.order.installments)",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.purchaseOrderInstallment.order.installments
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(purchaseOrderInstallment.order.orderDocumentType.name)",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.purchaseOrderInstallment.order
                                  .orderDocumentType.name
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(purchaseOrderInstallment.status.name)",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c(
                            "span",
                            {
                              style:
                                "background-color: " +
                                item.purchaseOrderInstallment.status
                                  .background +
                                "; color: " +
                                item.purchaseOrderInstallment.status.color +
                                ";padding: 2px 5px; border-radius: 5px; display: block; align-items: center; align-content: center;",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.purchaseOrderInstallment.status.name
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(purchaseOrder)",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatDate(item.purchaseOrder.created_at)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1266637254
                ),
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }